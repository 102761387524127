import { SelectionModel } from '@angular/cdk/collections';
import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogActions,
  MatDialogClose,
  MatDialogConfig,
  MatDialogContent,
  MatDialogTitle,
} from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslocoModule } from '@jsverse/transloco';
import { TypeOfFile } from '@shared/data-access/common';
import { ucFirst } from '@shared/util/code';
import { fadeInOnEnterAnimation, fadeOutOnLeaveAnimation, rotateInOnEnterAnimation, rotateOutOnLeaveAnimation } from 'angular-animations';
import { NgStringPipesModule } from 'ngx-pipes';
import { firstValueFrom } from 'rxjs';
import { CloseOnEscapeDirective } from '../../../ui/dialog/close-on-escape.directive';
import { File } from '../core/fileupload.service';
import { OverviewComponent } from '../overview/overview.component';
import { UploadComponent } from '../upload/upload.component';

export interface ConfigSelectionModel {
  fileIds: string[];
  multiple: boolean;
  typeOfFile: TypeOfFile;
  showUseAssetButton: boolean;
}

@Component({
  selector: 'lsf-fileupload-dialog',
  templateUrl: './fileupload-dialog.component.html',
  styleUrls: ['./fileupload-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    fadeInOnEnterAnimation({ duration: 300 }),
    fadeOutOnLeaveAnimation({ duration: 300 }),
    rotateInOnEnterAnimation({ degrees: -100, duration: 300 }),
    rotateOutOnLeaveAnimation({ degrees: -100, duration: 300 }),
  ],
  imports: [
    TranslocoModule,
    MatDialogTitle,
    MatButtonModule,
    MatTooltipModule,
    MatIconModule,
    NgIf,
    CloseOnEscapeDirective,
    MatDialogContent,
    MatSidenavModule,
    UploadComponent,
    OverviewComponent,
    MatDialogActions,
    MatDialogClose,
    NgStringPipesModule,
  ],
})
export class FileUploadDialogComponent implements OnInit {
  readonly documentFileType = TypeOfFile.Document;
  readonly ucfirst = ucFirst;

  selectedFileIds: string[] = null!;
  selectedFiles: File[] = null!;
  hasSelectionModel = false;
  typeOfFile: TypeOfFile;
  showUseAssetButton: boolean;
  multiple = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ConfigSelectionModel,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    this.typeOfFile = data.typeOfFile;
    this.showUseAssetButton = data.showUseAssetButton;
  }

  static async open(dialog: MatDialog, config?: MatDialogConfig<ConfigSelectionModel>): Promise<File[] | undefined> {
    return await firstValueFrom(dialog.open<unknown, unknown, File[]>(FileUploadDialogComponent, config).afterClosed());
  }

  getToolTipText(drawerOpened: boolean) {
    return drawerOpened
      ? this.typeOfFile === this.documentFileType
        ? 'assets._actions.cancel_upload_file'
        : 'assets._actions.cancel_upload_image'
      : this.typeOfFile === this.documentFileType
        ? 'assets._actions.add_file'
        : 'assets._actions.add_image';
  }

  ngOnInit() {
    if (this.data.multiple) {
      this.multiple = this.data.multiple;
    }
    this.hasSelectionModel = true;
    this.selectedFileIds = this.data.fileIds;
  }

  onSelectionChange(selectionModel: SelectionModel<File>) {
    this.selectedFiles = selectionModel.selected;
    this.changeDetectorRef.markForCheck();
  }
}
