<ng-template [ngIf]="files?.length === 0" *transloco="let trl">
  <div class="p-3 w-100 h-100 d-flex flex-column justify-content-center align-items-center">
    <div class="mat-headline-6 mb-1">
      {{ trl(typeOfFile === imageFileOfType ? "assets._sentences.No_images_yet" : "assets._sentences.No_files_yet") }}
    </div>
    <div class="mat-subtitle-2 text-center">
      <div>{{ trl(typeOfFile === imageFileOfType ? "assets._sentences.Start_adding_images" : "assets._sentences.Start_adding_files") }}</div>
    </div>
    <div class="arrow"></div>
  </div>
</ng-template>

<div class="h-100" *transloco="let trl">
  <div class="p-3 d-flex flex-wrap">
    <div
      class="me-2 d-inline-block mat-elevation-z1 mb-2 image-card"
      *ngFor="let file of files; let i = index; trackBy: trackList"
      [@bounceOut]="deletedFileId === file.id"
      (@bounceOut.done)="removeFile($event)"
    >
      <div class="d-flex flex-column h-100" [@cardFadeAnimation]="'in'">
        <button type="button" class="image-card-header" (click)="onToggleSelect(file)">
          <div class="image-card-gradient z-2"></div>
          <div *ngIf="typeOfFile === imageFileOfType">
            <img class="img-fluid image-card-image" [ngSrc]="file.src" [attr.alt]="file.altText" fill />
          </div>
          <div *ngIf="typeOfFile !== imageFileOfType">
            <img
              class="img-fluid image-card-image"
              ngSrc="./assets/icons/{{ file.name.substring(file.name.lastIndexOf('.') + 1) }}.png"
              [attr.alt]="file.altText"
              fill
            />
          </div>
        </button>

        <div class="p-1 d-flex flex-column flex-grow-1">
          <div class="mat-caption truncate-overflow image-card-name" [matTooltip]="file.name">{{ file.name }}</div>

          <div class="image-card-attr">
            <mat-form-field appearance="fill" class="w-100 mb-0">
              <mat-label>{{ trl(typeOfFile === imageFileOfType ? "assets.image_alt" : "assets.file_title") | ucfirst }}</mat-label>
              <input matInput [(ngModel)]="file.altText" />
              <button type="button" (click)="onSaveDescription(file)" matSuffix mat-mini-fab [matTooltip]="ucfirst(trl('common._actions.apply'))">
                <mat-icon>check</mat-icon>
              </button>
            </mat-form-field>
          </div>
          <div class="d-flex align-items-center">
            <mat-checkbox
              *ngIf="hasSelection"
              [checked]="selection.isSelected(file)"
              [matTooltip]="trl(typeOfFile === imageFileOfType ? 'assets._actions.select_image' : 'assets._actions.select_file')"
              (change)="change($event, file)"
            ></mat-checkbox>
            <div *ngIf="typeOfFile !== imageFileOfType">
              <a target="_blank" title="{{ file.name }}" href="{{ file.src }}">
                <mat-icon>download</mat-icon>
              </a>
            </div>
            <button
              [disabled]="file.isUsed"
              mat-icon-button
              class="ms-auto"
              [matTooltip]="ucfirst(trl('common._actions.delete'))"
              color="primary"
              (click)="onDeleteFile(file)"
            >
              <mat-icon>delete_forever</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
