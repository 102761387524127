import { animate, AnimationEvent, state, style, transition, trigger } from '@angular/animations';
import { SelectionModel } from '@angular/cdk/collections';
import { NgFor, NgIf, NgOptimizedImage } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxChange, MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslocoModule, TranslocoService } from '@jsverse/transloco';
import { TypeOfFile } from '@shared/data-access/common';
import { DialogConfirmComponent } from '@shared/ui/dialog';
import { ucFirst } from '@shared/util/code';
import { bounceOutAnimation } from 'angular-animations';
import { NgStringPipesModule } from 'ngx-pipes';
import { firstValueFrom } from 'rxjs';
import { File, FileService } from '../core/fileupload.service';

@Component({
  selector: 'lsf-fileupload-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    bounceOutAnimation({ duration: 500 }),
    trigger('cardFadeAnimation', [
      state('in', style({ opacity: 1 })),
      transition(':enter', [style({ opacity: 0 }), animate(600)]),
      transition(':leave', animate(600, style({ opacity: 0 }))),
    ]),
  ],
  imports: [
    TranslocoModule,
    NgIf,
    NgFor,
    MatTooltipModule,
    MatFormFieldModule,
    NgOptimizedImage,
    MatInputModule,
    FormsModule,
    MatButtonModule,
    MatIconModule,
    MatCheckboxModule,
    NgStringPipesModule,
  ],
})
export class OverviewComponent implements OnInit, AfterViewInit {
  files: File[] = null!;
  selection = new SelectionModel<File>(true);
  hasSelection = false;
  itemSize: number = null!;
  deletedFileId: string | null = null!;
  readonly imageFileOfType = TypeOfFile.Image;
  readonly ucfirst = ucFirst;
  @Input() maxSelection: number | null = null;
  @Input() typeOfFile!: TypeOfFile;

  @Input() set multiple(multiple: boolean) {
    const selected = this.selection.selected;
    this.selection = new SelectionModel<File>(multiple, selected);
  }

  @Input() set selectedFileIds(files: string[]) {
    this._selectedFileIds = files;
    this.hasSelection = true;
  }

  get selectedFileIds(): string[] {
    return this._selectedFileIds;
  }

  @Output() selectionChange = new EventEmitter<SelectionModel<File>>();
  private _selectedFileIds: string[] = null!;

  constructor(
    private elementRef: ElementRef,
    private changeDetectorRef: ChangeDetectorRef,
    private matDialog: MatDialog,
    private snackBar: MatSnackBar,
    private fileService: FileService,
    private translocoService: TranslocoService,
    private destroyRef: DestroyRef,
  ) {
    this.fileService.files$.pipe(takeUntilDestroyed()).subscribe((x) => this.setFiles(x));
  }

  ngOnInit() {
    this.selection.changed.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => this.selectionChange.emit(this.selection));
    this.fileService.getFiles(this.typeOfFile);
  }

  ngAfterViewInit() {
    const width = this.elementRef.nativeElement.getBoundingClientRect().width;
    const cardWidth = 150 + 16; // TODO should be calculated
    const cardsPerRow = Math.floor(width / cardWidth);
    const cardHeight = 246 + 16; // TODO should be calculated
    this.itemSize = Math.round(cardHeight / cardsPerRow);
  }

  async onSaveDescription(file: File) {
    await firstValueFrom(this.fileService.updateFile(file));
    this.changeDetectorRef.markForCheck();
    this.snackBar.open(
      this.translocoService.translate(
        this.typeOfFile === this.imageFileOfType ? `assets._sentences.Description_image_changed` : 'assets._sentences.Description_file_changed',
      ),
    );
  }

  async onDeleteFile(file: File) {
    const data = {
      title: ucFirst(this.translocoService.translate(this.typeOfFile === this.imageFileOfType ? 'assets.delete_image' : 'assets.delete_file')),
      message: this.translocoService.translate(
        this.typeOfFile === this.imageFileOfType ? 'assets._sentences.Are_you_sure_delete_image' : 'assets._sentences.Are_you_sure_delete_file',
      ),
      okButton: ucFirst(this.translocoService.translate('common.yes_I_am_sure')),
    };
    const apply = await DialogConfirmComponent.open(this.matDialog, { data });

    if (apply) {
      await firstValueFrom(this.fileService.deleteFile(file.id));
      this.selection.deselect(file);
      this.deletedFileId = file.id;
      this.changeDetectorRef.markForCheck();
      this.snackBar.open(
        this.translocoService.translate(
          this.typeOfFile === this.imageFileOfType ? `assets._sentences.The_image_is_deleted` : `assets._sentences.The_file_is_deleted`,
        ),
      );
    }
  }

  removeFile(event: AnimationEvent): void {
    const element = event.element;
    if (event.toState && event.toState !== 'void') {
      element.parentNode.removeChild(element);
      this.deletedFileId = null;
    }
  }

  trackList = (index: number, file: File) => file.id;

  change(evt: MatCheckboxChange, file: File) {
    evt.checked ? this.selection.select(file) : this.selection.deselect(file);
  }

  onToggleSelect(file: File) {
    this.selection.isSelected(file) ? this.selection.deselect(file) : this.selection.select(file);
  }

  private setFiles(files: File[]) {
    this.files = files;
    this.files.forEach((x) => {
      if (this.selectedFileIds.includes(x.id)) {
        this.selection.select(x);
      }
    });

    this.changeDetectorRef.markForCheck();
    this.changeDetectorRef.detectChanges();
  }
}
