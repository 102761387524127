import { Inject, Injectable } from '@angular/core';
import { FileClient, FileModel, FileParameter, TypeOfFile } from '@shared/data-access/common';
import { API_PORTAL_BASE_URL } from '@shared/util/infrastructure';
import { Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';

export interface File extends FileModel {
  src: string;
}

@Injectable({
  providedIn: 'root',
})
export class FileService {
  files$: Subject<File[]> = new Subject();
  files: File[] = null!;
  apiPortalBaseUrl: string = null!;

  constructor(
    private fileUploadClient: FileClient,
    @Inject(API_PORTAL_BASE_URL) baseUrl: string,
  ) {
    this.apiPortalBaseUrl = baseUrl;
  }

  getFilesByIds(ids: string[]): Observable<File[]> {
    return this.fileUploadClient.getFilesByIds(ids).pipe(
      map((f) => {
        const files = f as File[];
        files.forEach((x) => (x.src = `${this.apiPortalBaseUrl}/api/File/${x.id}`));
        return files;
      }),
    );
  }

  getFiles(typeOfFile: TypeOfFile) {
    this.fileUploadClient.getFiles(typeOfFile).subscribe((x) => {
      this.files = x as File[];
      this.files.forEach((x) => (x.src = `${this.apiPortalBaseUrl}/api/File/${x.id}`));
      this.files.sort((a, b) => {
        const x = a.name.toLowerCase();
        const y = b.name.toLowerCase();
        return x < y ? -1 : x > y ? 1 : 0;
      });
      this.files$.next(this.files);
    });
  }

  deleteFile(id: string): Observable<void> {
    return this.fileUploadClient.deleteFile(id);
  }

  updateFile(file: File) {
    return this.fileUploadClient.updateFile(file);
  }

  addFile(fileParameter: FileParameter, name: string, typeOfFile: TypeOfFile): Observable<string> {
    return this.fileUploadClient.addFile(name, typeOfFile, fileParameter).pipe(tap(() => this.getFiles(typeOfFile)));
  }
}
